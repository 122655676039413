<template>
  <div>
    <!-- 基本信息 -->
    <div class="basicInfo">
      <div class="infoTit">
        <div>基本信息</div>
        <!-- <div @click="showDrawer"><a>编辑</a></div> -->
      </div>
      <div class="basicInfo_edit">
        <a-form-model layout="inline" :model="form">
          <a-form-model-item label="所属公司">
            <a-select
              v-model="form.qbcId"
              placeholder="请输入"
              style="width: 150px"
              :disabled="viewShow"
            >
              <a-select-option
                v-for="item in GetCompanyList"
                :key="item.bc_id"
                :value="item.bc_id"
              >
                {{ item.bc_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- <a-form-model-item label="报价名称">
            <a-input
              v-model="form.qname"
              placeholder="请输入"
              :disabled="viewShow"
            />
          </a-form-model-item> -->
          <a-form-model-item label="项目名称">
            <a-tooltip>
              <template slot="title"> 小区名称-楼号-单元号-户号 </template>
              <a-icon type="exclamation-circle" />
            </a-tooltip>
            <a-input
              v-model="form.qname"
              placeholder="请输入"
              style="width: 140px"
              :disabled="viewShow"
            />
          </a-form-model-item>
          <a-form-model-item label="报价类型">
            <a-select
              v-model="form.qtype"
              style="width: 150px"
              :disabled="viewShow"
            >
              <a-select-option
                v-for="item in QuotationType"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <br />
          <a-form-model-item label="套餐名称">
            <!-- <a-input v-model="form.ptName" placeholder="请输入" /> -->

            <a-select
              v-model="form.qptId"
              style="width: 150px"
              :disabled="viewShow"
            >
              <a-select-option
                v-for="item in PackageTemplateList"
                :key="item.ptId"
                :value="item.ptId"
              >
                {{ item.ptName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="套餐编号">
            <!-- <a-input v-model="form.qNumber" placeholder="请输入" disabled /> -->
            <div class="width240">{{ form.qNumber }}</div>
          </a-form-model-item>
          <a-form-model-item label="套餐类别">
            <!-- <a-input v-model="form.qPtPtId" placeholder="请输入" disabled /> -->
            <div class="width240">{{ form.qPtPtId }}</div>
          </a-form-model-item>
          <br />
          <a-form-model-item label="施工量(平方米)">
            <a-input
              v-model="form.qarea"
              placeholder="请输入"
              :disabled="viewShow"
            />
          </a-form-model-item>
          <a-form-model-item label="单价">
            <!-- <a-input v-model="form.qmoney" placeholder="请输入" disabled /> -->
            <div class="width240">￥{{ form.qPackageMoney }}</div>
            <!-- :disabled="viewShow" -->
          </a-form-model-item>

          <a-form-model-item label="套餐价(元)">
            <div class="colorChange">￥{{ form.qpackage }}</div>
          </a-form-model-item>
          <br />
          <!-- 卧室个数 -->
          <a-form-model-item label="房型：" class="width140">
            <a-input
              v-model="form.qroom"
              :disabled="viewShow"
              class="width50"
            />
            室
          </a-form-model-item>
          <!-- 客厅个数 -->
          <a-form-model-item label="" class="width80">
            <a-input
              v-model="form.qhall"
              :disabled="viewShow"
              class="width50"
            />
            厅
          </a-form-model-item>
          <!-- 厨房个数 -->
          <a-form-model-item label="" class="width80">
            <a-input
              v-model="form.qkitchen"
              :disabled="viewShow"
              class="width50"
            />
            厨
          </a-form-model-item>
          <!-- 卫生间个数 -->
          <a-form-model-item label="" class="width80">
            <a-input
              v-model="form.qtoilet"
              :disabled="viewShow"
              class="width50"
            />
            卫
          </a-form-model-item>
          <!-- 阳台个数 -->
          <a-form-model-item label="" class="width140">
            <a-input
              v-model="form.qbalcony"
              :disabled="viewShow"
              class="width50"
            />
            阳台
          </a-form-model-item>
          <a-form-model-item label="客户姓名">
            <a-input
              v-model="form.qcusName"
              placeholder="请输入"
              :disabled="viewShow"
            />
          </a-form-model-item>
          <a-form-model-item label="联系电话">
            <a-input
              v-model="form.qcusTel"
              placeholder="请输入"
              :disabled="viewShow"
            />
          </a-form-model-item>
          <br />
          <div class="textareaWidth">
            <a-form-model-item label="工地地址">
              <a-textarea
                v-model="form.qaddress"
                placeholder="请输入"
                :auto-size="{ minRows: 1, maxRows: 3 }"
                :disabled="viewShow"
              />
            </a-form-model-item>
            <a-form-model-item label="说明">
              <a-textarea
                v-model="form.qdesc"
                placeholder="请输入"
                :auto-size="{ minRows: 1, maxRows: 3 }"
                :disabled="viewShow"
              />
            </a-form-model-item>
            <br />
            <!-- <a-form-model-item label="套餐概要">
              <a-textarea
                v-model="form.qSummary"
                placeholder="请输入"
                :auto-size="{ minRows: 1, maxRows: 3 }"
                :disabled="viewShow"
              />
            </a-form-model-item> -->
          </div>
          <!-- <a-form-model-item :wrapper-col="buttonItemLayout.wrapperCol">
            <a-button type="primary"> 保存 </a-button>
            <a-button style="margin-left: 10px" @click="onClose">
              取消
            </a-button>
          </a-form-model-item> -->
        </a-form-model>
      </div>
    </div>
    <!-- 详细信息 -->
    <div>
      <div class="infoTit">详细信息</div>
      <div class="tableHeight">
        <!--       :scroll="{ y: 550 }"
          tableLayout="fixed" -->
        <div class="addBtn" v-if="!viewShow">
          <a-button
            type="primary"
            @click="showDrawer('QuotationEditShow', '新增', 'add', '')"
            >新增</a-button
          >
        </div>
        <!--      rowKey="qdPId"   WorkProceList.length > 0 &&ProjectList -->
        <a-table
          ref="table"
          size="small"
          :rowKey="
            (record, index) => {
              return record.wpId
                ? record.wpId
                : record.qdWpId + '-' + record.qdPId;
            }
          "
          :columns="columns"
          :data-source="tabledata"
          :scroll="{ x: 1500, y: 400 }"
          :defaultExpandAllRows="true"
          v-if="tableShow"
          :pagination="false"
        >
          <!-- :showPagination="false" -->
          <span slot="wpId" slot-scope="text">
            <div>{{ WorkProceResult(text) }}</div>
          </span>
          <!-- <p slot="expandedRowRender" slot-scope="record" style="margin: 0">
            {{ record.children }}
          </p> -->
          <span slot="qdPId" slot-scope="text">
            <div>
              {{ ProjectListResult(text) ? ProjectListResult(text) : text }}
            </div>
          </span>
          <span slot="qdCreateTime" slot-scope="text, record">
            {{ record.tproject ? record.tproject.punit : record.qdUnit }}
          </span>
          <span slot="qdBcId" slot-scope="text">
            <div>{{ text ? CompanyListResult(text) : "" }}</div>
          </span>
          <!-- 属性 -->
          <span slot="qdAttribute" slot-scope="text">
            <div>{{ text | AttributeList }}</div>
          </span>
          <!-- 操作 -->
          <span slot="action" slot-scope="text, record" class="action">
            <a
              class="editBtn"
              type="primary"
              @click="showDrawer('QuotationEditShow', '新增', 'add', record)"
              v-show="!viewShow"
              >新增</a
            >
            <a
              v-if="record.qdPId && !viewShow"
              class="editBtn"
              @click="showDrawer('QuotationEditShow', '修改', 'edit', record)"
              >修改</a
            >
            <!--  @click="showDrawer('BuildPackageEdit', '修改', 'edit', record)" -->
            <a
              class="editBtn"
              v-if="record.qdPId"
              @click="showDrawer('QuotationViewShow', '详情', 'view', record)"
              >详情</a
            >
            <a-popconfirm
              title="确定删除此条报价信息吗?"
              @confirm="() => onDelete(record)"
            >
              <a class="delName" v-if="record.qdPId && !viewShow">删除</a>
            </a-popconfirm>
          </span>
        </a-table>
      </div>
      <div class="btnsDisplayflex">
        <div class="btnsDisplayflex">
          <div>额外收费项</div>
          <div v-if="viewShow" class="btnsDisplayflex_inner">
            <div v-for="(item, index) in this.NewExtraCharge" :key="index">
              {{ ExtraChargeListResult(item.qdPId) }}
            </div>
          </div>

          <div>
            <a-select
              mode="tags"
              placeholder="请选择"
              v-model="NewExtraChargeValue"
              v-if="!viewShow"
              @change="handleChangeExtra"
              style="width: 200px"
            >
              <a-select-option
                v-for="el in ExtraChargeList"
                :key="el.ecId"
                :value="el.ecId"
              >
                {{ el.ecName }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div class="btnsDisplayflexPrice">￥{{ ExtraChargePrice }}</div>
      </div>
      <a-divider />
      <div class="bottom">
        <div class="displayFlex">
          <div>总金额</div>
          <div>￥{{ Math.round(form.qtotal * 100) / 100 }}</div>
        </div>
        <div class="displayFlex">
          <div>套餐价</div>
          <div>￥{{ Math.round(form.qpackage * 100) / 100 }}</div>
        </div>
        <div class="displayFlex">
          <div>优惠</div>
          <div>￥{{ Math.round(form.qcheap * 100) / 100 }}</div>
        </div>
        <a-divider />
        <div class="displayFlex">
          <div>调整费用</div>
          <div>￥{{ Math.round(form.qchange * 100) / 100 }}</div>
        </div>
        <div class="displayFlex">
          <div>减少项</div>
          <div>￥{{ Math.round(form.qreduce * 100) / 100 }}</div>
        </div>
        <div class="displayFlex">
          <div>增加项</div>
          <div>￥{{ Math.round(form.qincrease * 100) / 100 }}</div>
        </div>
        <!-- <div class="displayFlex">
          <div>主材总金额</div>
          <div>￥{{ Math.round(form.qmmoney * 100) / 100 }}</div>
        </div> -->
        <div class="displayFlex">
          <div>
            实际价格
            <a-tooltip placement="right">
              <template slot="title">
                <!-- <div>实际价格=套餐价-减少项+增加项+调整费用+主材金额</div> -->
                <div>
                  实际价格=总金额-优惠金额-减少项+增加项+调整费用+额外收费项
                </div>
              </template>
              <a-icon type="question-circle" theme="filled" />
            </a-tooltip>
          </div>
          <div>￥{{ form.qmoney.toFixed(2) }}</div>
        </div>
      </div>

      <div class="padding_bottom">
        <!-- <a-button type="primary" @click="submitClick()">提交</a-button>
        <a-button @click="cancellClick()" style="margin-left: 20px"
          >取消</a-button
        > -->
        <div v-if="viewShow">
          <a-button type="primary" @click="showEdit()">编辑</a-button>
          <a-button @click="cancellClick()" style="margin-left: 20px"
            >返回</a-button
          >
        </div>
        <div v-if="!viewShow">
          <a-button type="primary" @click="submitClick()">提交</a-button>

          <a-button @click="cancellClick()" style="margin-left: 20px"
            >取消</a-button
          >
        </div>
      </div>
    </div>
    <a-drawer
      :title="DrawerTitle"
      placement="right"
      :closable="false"
      :visible="visibleDrawer"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
      :width="750"
      :destroyOnClose="true"
    >
      <QuotationEdit
        @onClose="onClose"
        :record="JSON.stringify(record)"
        :type="recordType"
        ref="QuotationEdit"
        v-if="QuotationEditShow"
        @tabledataChange="tabledataChange"
      />
      <QuotationView
        @onClose="onClose"
        :record="JSON.stringify(record)"
        :type="recordType"
        ref="QuotationView"
        v-if="QuotationViewShow"
      />
      <!-- @tabledataChange="($event) => tabledataChange()" -->
    </a-drawer>
  </div>
</template>

<script>
import { GetCompanies } from "@/api/device";
import { STable, SSearch, ColumnsSet } from "@/components";
import { AddQuotation, UpdateQuotation } from "@/api/apiJF/quotation";
import { GetPackageTemplateList, GetPackageDetail } from "@/api/apiJF/package";
import {
  GetWorkProceList,
  GetPackageTypeList,
  DeleteWorkProce,
  GetProjectList,
  GetExtraChargeList,
  GetProjectMaterialList,
} from "@/api/apiJF/template";
import { GetQuotationDetailList } from "@/api/apiJF/quotation";
import QuotationEdit from "./QuotationEdit.vue";
import QuotationView from "./QuotationView.vue";

export default {
  name: "BuildQuotation",
  components: { QuotationEdit, STable, QuotationView },
  data() {
    return {
      viewShow: true, //是否显示详情
      queryParam: {
        qdQId: "", //报价id
        qdBcIds: "18",
        qdWpId: "", //施工工序id
        qdPId: "", //施工项id
        qdType: "", //施工类型，1基础工程2额外收费
      },
      DrawerTitle: "",
      GetCompanyList: {},
      CompanyListid: [],
      CompanyList: {},
      PackageTypeListId: {}, //套餐类别
      PackageTypeList: [],
      tablePriceList: [],
      // columns,
      columns: [
        {
          title: "施工工序",
          dataIndex: "wpId",
          key: "wpId",
          width: "100px",
          // align: "left",
          scopedSlots: { customRender: "wpId" },
        },
        {
          title: "施工项",
          dataIndex: "qdPId",
          key: "qdPId",
          // width: "100px",
          // align: "left",
          scopedSlots: { customRender: "qdPId" },
        },
        {
          title: "公司名称",
          dataIndex: "qdBcId",
          key: "qdBcId",
          width: "100px",
          // align: "left",
          scopedSlots: { customRender: "qdBcId" },
        },
        {
          title: "施工量",
          dataIndex: "qdQuantity",
          key: "qdQuantity",
          customRender: (text, record) => {
            let value = Math.round(text * 100) / 100;
            return value ? value : "";
          },
          width: "90px",
          align: "center",
        },
        {
          title: "工程计量单位",
          dataIndex: "qdCreateTime",
          key: "qdCreateTime",
          width: "90px",
          align: "center",
          scopedSlots: { customRender: "qdCreateTime" },
        },
        {
          title: "核算单位单价(元)",
          dataIndex: "qdPrice",
          key: "qdPrice",
          width: "110px",
          align: "center",
        },
        {
          title: "总金额(元)",
          dataIndex: "qdMoney",
          customRender: (text, record) => {
            let value = Math.round(text * 100) / 100;
            return value ? value : "";
          },
          width: "90px",
          align: "center",
        },
        {
          title: "主材",
          dataIndex: "mainMaterials",
          // key: "mainMaterials",
          // scopedSlots: { customRender: "mainMaterials" },
          customRender: (text, record) => {
            if (text) {
              // console.log("表格里的主材", text.length);
              // let maMId = JSON.parse(text)[0].maMId;
              // console.log(maMId);
              let idList = [];

              // if (text.length > 50) {
              if (typeof text == "string") {
                JSON.parse(text).forEach((el) => {
                  // console.log(
                  //   "this.ProjectMaterialResult(el.maMId)",
                  //   this.ProjectMaterialResult(el.maMId)
                  // );
                  idList.push(this.ProjectMaterialResult(el.maMId));
                  idList.push(<br />);
                });
              } else {
                if (typeof text == "object") {
                  text.forEach((el) => {
                    // console.log(
                    //   "this.ProjectMaterialResult(el.maMId)2222",
                    //   this.ProjectMaterialResult(el.maMId)
                    // );
                    idList.push(this.ProjectMaterialResult(el.maMId));
                    idList.push(<br />);
                  });
                }
              }
              return idList;
            } else {
              return "";
            }
          },
          width: "180px",
          align: "center",
        },
        {
          title: "主材用量",
          dataIndex: "pmCreateTime",
          customRender: (text, record) => {
            if (record.mainMaterials) {
              // console.log(
              //   "record.mainMaterials",
              //   record.mainMaterials.length,
              //   record.mainMaterials
              // );
              let valueList = [];
              // if (record.mainMaterials.length > 50) {
              if (typeof record.mainMaterials == "string") {
                JSON.parse(record.mainMaterials).forEach((el) => {
                  let value = "";
                  this.ProjectMaterialListMain.forEach((item) => {
                    if (item.pmMId == el.maMId) {
                      value = el.maQuantity + item.tmaterial.mUnit;
                    }
                  });
                  valueList.push(value);
                  valueList.push(<br />);
                });
              } else {
                // if (record.mainMaterials.length > 2) {
                if (typeof record.mainMaterials == "object") {
                  record.mainMaterials.forEach((el) => {
                    let value = "";
                    this.ProjectMaterialListMain.forEach((item) => {
                      if (item.pmMId == el.maMId) {
                        value = el.maQuantity + item.tmaterial.mUnit;
                      }
                    });
                    valueList.push(value);
                    valueList.push(<br />);
                  });
                }
              }
              return valueList;
            }
          },
          width: "90px",
          align: "center",
        },
        {
          title: "主材费用(元)",
          // qdOrder
          // dataIndex: "qdPrice",
          // key: "qdPrice",
          dataIndex: "qdOrder",
          customRender: (text, record) => {
            if (record.mainMaterials) {
              let valueList = [];
              // console.log(
              //   "record.mainMaterials.length",
              //   record.mainMaterials.length
              // );

              // if (record.mainMaterials.length > 20) {
              if (typeof record.mainMaterials == "string") {
                // console.log(
                //   "  record.mainMaterials",
                //   JSON.parse(record.mainMaterials)
                // );
                JSON.parse(record.mainMaterials).forEach((el) => {
                  // record.mainMaterials.forEach((el) => {
                  let value = "";
                  this.ProjectMaterialListMain.forEach((item) => {
                    if (item.pmMId == el.maMId) {
                      // console.log(item, el);

                      value = el.maQuantity * item.tmaterial.mSalesPrice;
                      // value = value.toFixed(2);
                      value = Math.round(value * 100) / 100;
                      // console.log("主材value", value);
                    }
                  });
                  valueList.push(value);
                  valueList.push(<br />);
                });
              } else {
                // if (record.mainMaterials.length > 2) {
                if (typeof record.mainMaterials == "object") {
                  record.mainMaterials.forEach((el) => {
                    let value = "";
                    this.ProjectMaterialListMain.forEach((item) => {
                      if (item.pmMId == el.maMId) {
                        value = el.maQuantity * item.tmaterial.mSalesPrice * 1;
                        value = Math.round(value * 100) / 100;
                        // console.log("主材value", value);
                      }
                    });
                    valueList.push(value);
                    valueList.push(<br />);
                  });
                }
              }
              return valueList;
            }
          },
          width: "90px",
          align: "center",
        },
        {
          title: "属性",
          dataIndex: "qdAttribute",
          key: "qdAttribute",
          scopedSlots: { customRender: "qdAttribute" },
          width: "100px",
          align: "center",
        },
        {
          title: "备注",
          dataIndex: "qdDesc",
          key: "qdDesc",
          width: "150px",
          align: "center",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
          width: "200px",
          align: "center",
          fixed: "right",
        },
      ],
      WorkProceList: [], //施工工序
      ProjectList: [], //施工项
      ExtraChargeList: [], //额外收费项
      ProjectMaterialListMain: [], //主材物料信息
      ProjectMaterialListMainLis: [], //主材物料信息id
      visibleDrawer: false,
      form: {
        qbcId: "", //分公司id
        qname: "", //报价名称
        qNumber: "", //编号
        qptId: "", //套餐Id
        qPtPtId: "", //套餐类别ID
        qName: "", //套餐名称
        ptUnit: "", //报价单位
        qdesc: "", //备注说明
        qPackageMoney: 0, //套餐单价
        qtype: "", //报价类型
        qcusName: "", //客户姓名
        qcusTel: "", //客户电话
        qdesName: "", //设计师姓名
        qpartyB: "", //乙方
        qarea: 0, //房屋面积
        qroom: "", //卧室个数
        qhall: "", //客厅个数
        qkitchen: "", //厨房个数
        qtoilet: "", //卫生间个数
        qbalcony: "", //阳台个数
        qSummary: "", //套餐概要
        qtotal: 0, //总金额
        qpackage: 0, //套餐价
        qcheap: 0, //优惠金额
        qchange: 0, //调整费用
        qreduce: 0, //减少项
        qincrease: 0, //增加项
        qmoney: 0, //实际价格
        // qmmoney: 0, //主材总金额
        qaddress: "", //工地地址
      },
      tabledata: [],
      record: {
        qdBcId: "",
      },
      recordType: "",
      NewExtraCharge: [],
      NewExtraChargeValue: [],
      ExtraChargeListId: {},
      isShowcancellBtn: true,
      QuotationType: [
        { value: 1, label: "量房前报价" },
        { value: 2, label: "量房后报价" },
        { value: 3, label: "签约报价" },
      ],
      PackageTemplateList: [], //套餐模版
      tableShow: false,
      QuotationEditShow: false,
      QuotationViewShow: false,
      PackageTypeListPrice: [], //套餐模版id和价格
      ExtraChargePrice: 0, //额外收费项总金额
      tablePrice: 0, //表格总金额之和
      oldTableData: [], //原始表格数据，编辑时，是query里的project，add时，是套餐里的赋值的表格数据
      addTableData: [],
      qincrease_table: 0, //表格里的增加项
    };
  },
  beforeCreate() {
    // let query = JSON.parse(this.$route.query.record);
    // console.log("beforeCreate---query.record", query);
    // this.form.qbcId = query.qdBcId;
  },
  created() {
    this.GetCompaniesId();
    // console.log("this.$route.query", this.$route.query);
    // 路由传参，循环赋值
    let query = JSON.parse(this.$route.query.record);
    console.log("query.record", query);
    if (query) {
      if (Object.keys(query).length != 0) {
        for (let el in query) {
          this.form[el] = query[el];
        }
        this.queryParam.qdQId = query.qid;
        this.queryParam.qdBcIds = query.qbcId;
        this.record.qdBcId = query.qdBcId;
        this.record.qdQId = query.qid;
        // 如果是编辑 则查询详细信息
        // 查询套餐详情
        this.GetPackageDetail(this.form.qptId, this.form.qbcId);
        //查询主材物料信息
        this.GetProjectMaterialList(this.form.qbcId);
        // console.log("this.$route.query.type", this.$route.query.type);
        // 获取额外收费项
        this.GetExtraChargeList(this.form.qbcId);
      }
    }
    if (this.$route.query.type == "edit") {
      this.loadData();
      this.$route.meta.title = "报价详情";
    } else {
      this.tabledata = [];
      this.viewShow = false;
      this.$route.meta.title = "新增报价";
    }
    // console.log("  this.form", this.form);
    setTimeout(() => {
      this.tableShow = true;
    }, 300);
  },
  mounted() {
    // console.log("获取角色信息", this.$store.state);
  },
  computed: {},
  watch: {
    "form.qbcId": {
      handler(newValue) {
        // console.log("form.qbcId", this.form.qbcId);
        // this.tabledata = [];
        if (newValue) {
          // 获取套餐类别
          this.GetPackageTypeList(newValue);
          this.record.qdBcId = newValue;
          // 获取额外收费项
          this.GetExtraChargeList(newValue);
          // 获取施工工序
          this.GetWorkProceList(newValue);
          // 获取施工项
          this.GetProjectList(newValue);
          // 获取套餐模版
          this.GetPackageTemplateList(newValue);
          //查询主材物料信息
          this.GetProjectMaterialList(newValue);
        }
      },
      deep: true,
      immediate: true,
    },
    "form.qptId": {
      handler(newValue) {
        this.PackageTemplateList.forEach((el) => {
          if (el.ptId == newValue) {
            // console.log("选中的套餐", el);
            this.form.qPtPtId = this.PackageTypeListResult(el.ptPtId); //套餐类别
            this.form.qNumber = el.ptNumber; //套餐编号
            this.form.qName = el.ptName;
            this.form.qPackageMoney = el.ptPrice;
            // 查询套餐详情
            this.GetPackageDetail(newValue, this.form.qbcId);
            this.form.qpackage = this.form.qarea * el.ptPrice;
          }
        });
      },
      // immediate: true,
      // deep: true,
    },
    "form.qarea": {
      handler(newValue) {
        // console.log("form.qarea", newValue);
        // 计算套餐价
        if (!this.viewShow) {
          this.form.qpackage = this.form.qPackageMoney * this.form.qarea;
        }
        if (this.$route.query.type == "add") {
          this.tabledata.forEach((el) => {
            if (el.children) {
              el.children.forEach((elChild) => {
                // console.log("elChild", elChild);

                // 跟随施工量变化
                if (elChild.qdFollowChange == "1") {
                  // 施工量=建筑面积*比例
                  elChild.qdQuantity = newValue * elChild.qdRatio;
                  // // 计算金额
                  elChild.qdMoney = elChild.qdQuantity * elChild.qdPrice;
                } else {
                  // elChild.qdQuantity = newValue;
                  // 计算金额
                  elChild.qdMoney = elChild.qdQuantity * elChild.qdPrice;
                }
                // 主材

                // if (elChild.mainMaterials.length > 4) {
                if (typeof elChild.mainMaterials == "string") {
                  let dataMain = JSON.parse(elChild.mainMaterials);
                  dataMain.forEach((el) => {
                    // console.log("el", el);
                    el.maAttribute = el.maAttribute + "";
                    if (el.tprojectMaterial) {
                      el.maLoss = el.tprojectMaterial.pmLoss;
                      el.maUnitUsage = el.tprojectMaterial.pmUnitUsage;
                      el.maContain = el.tprojectMaterial.pmContain; //是否包含在施工项中
                      el.maUnit = el.tmaterial.mUnit; //单位
                      el.maSalesPrice = el.tmaterial.mSalesPrice; //单价
                    }
                    let maLoss = el.maLoss * 1 + 1;
                    // 材料用量=（1+损耗率)*单位用量*施工量
                    // el.maQuantity =
                    // maLoss * el.maUnitUsage * elChild.qdQuantity;
                    el.maQuantity = Math.ceil(
                      maLoss * el.maUnitUsage * elChild.qdQuantity
                    );
                    // console.log("主材随施工量改变", maLoss, el.maUnitUsage, value);
                    elChild.mainMaterials = dataMain;
                  });
                } else {
                  let dataMain = elChild.mainMaterials;
                  dataMain.forEach((el) => {
                    // console.log("el", el);
                    el.maAttribute = el.maAttribute + "";
                    if (el.tprojectMaterial) {
                      el.maLoss = el.tprojectMaterial.pmLoss;
                      el.maUnitUsage = el.tprojectMaterial.pmUnitUsage;
                      el.maContain = el.tprojectMaterial.pmContain; //是否包含在施工项中
                      el.maUnit = el.tmaterial.mUnit; //单位
                      el.maSalesPrice = el.tmaterial.mSalesPrice; //单价
                    }
                    let maLoss = el.maLoss * 1 + 1;
                    // 材料用量=（1+损耗率)*单位用量*施工量
                    el.maQuantity = Math.ceil(
                      maLoss * el.maUnitUsage * elChild.qdQuantity
                    );
                    // console.log("主材随施工量改变", maLoss, el.maUnitUsage, value);
                    elChild.mainMaterials = dataMain;
                  });
                }
                // 辅材
                if (elChild.subMaterials.length > 0) {
                  let dataAux = elChild.subMaterials;
                  dataAux.forEach((el) => {
                    console.log("el", el);
                    el.maAttribute = el.maAttribute + "";
                    if (el.tprojectMaterial) {
                      el.maLoss = el.tprojectMaterial.pmLoss;
                      el.maUnitUsage = el.tprojectMaterial.pmUnitUsage;
                      el.maContain = el.tprojectMaterial.pmContain; //是否包含在施工项中
                      el.maUnit = el.tmaterial.mUnit; //单位
                      el.maSalesPrice = el.tmaterial.mSalesPrice; //单价
                    }
                    let maLoss = el.maLoss * 1 + 1;
                    // 材料用量=（1+损耗率)*单位用量*施工量

                    el.maQuantity = Math.ceil(
                      maLoss * el.maUnitUsage * elChild.qdQuantity
                    );
                    // console.log("主材随施工量改变", maLoss, el.maUnitUsage, value);
                    elChild.subMaterials = dataAux;
                  });
                }
                elChild.qdQuantity = Math.round(elChild.qdQuantity * 100) / 100;
                // 计算金额
                // elChild.qdMoney = elChild.qdQuantity * elChild.qdPrice;
                elChild.qdMoney = Math.round(elChild.qdMoney * 100) / 100;
              });
            }
          });
          // 计算总金额
          this.countQtotal(this.tabledata);
          // 计算增加项
          this.countAddItem(JSON.stringify(this.tabledata));
          // 计算减少项
          this.countCutItem(this.tabledata);
        }
      },
      immediate: true,
    },
    // 监听表格数据， 获取表格内每条施工项金额   计算总金额
    // tabledata: {
    //   handler(newValue) {
    //     // console.log("表格", newValue);
    //     // console.log("表格监听到新增或删除", this.tabledata);
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    // 监听额外收费项， 获取额外收费项金额   计算总金额
    NewExtraCharge: {
      handler(newValue) {
        // if (!this.viewShow) {
        setTimeout(() => {
          // console.log("额外收费项111", newValue);
          // console.log("总额外收费项111", this.ExtraChargeList);
          let price = 0;
          newValue.forEach((el) => {
            this.ExtraChargeList.forEach((item) => {
              // console.log("item", el.qdPId, item.ecId);
              if (el.qdPId == item.ecId) {
                console.log("额外收费项金额", item.ecCost, price);
                // price = price * 1 + item.ecCost * 1;
                // 如果ectype==1 总金额比例  ==2 定额
                if (item.ecType == 2) {
                  price = price * 1 + item.ecCost * 1;
                } else {
                  price = price * 1 + this.form.qtotal * item.ecCost;
                }
              }
            });
          });
          this.ExtraChargePrice = Math.round(price * 100) / 100;
          // console.log("额外收费项总金额相加", this.ExtraChargePrice);
          //  实际价格=总金额-优惠金额-减少项+增加项+调整费用+额外收费项
          this.form.qmoney =
            this.form.qtotal -
            this.form.qcheap -
            this.form.qreduce +
            this.form.qincrease +
            this.form.qchange +
            this.ExtraChargePrice;
        }, 300);
        // }
      },
      // immediate: true,
      deep: true,
    },
    //监听总金额，计算优惠金额  优惠金额=总金额-套餐价
    //监听总金额，计算实际金额
    "form.qtotal": {
      handler(newValue) {
        if (!this.viewShow) {
          this.form.qcheap = this.form.qtotal - this.form.qpackage;
          //  实际价格=总金额-优惠金额-减少项+增加项+调整费用+额外收费项
          this.form.qmoney =
            this.form.qtotal -
            this.form.qcheap -
            this.form.qreduce +
            this.form.qincrease +
            this.form.qchange +
            this.ExtraChargePrice;
        }
      },
      deep: true,
    },

    //监听套餐价，计算优惠金额  优惠金额=总金额-套餐价
    "form.qpackage": {
      handler(newValue) {
        if (!this.viewShow) {
          this.form.qcheap = this.form.qtotal - this.form.qpackage;
        }
      },
      deep: true,
    },
    //监听优惠价，计算实际金额
    "form.qcheap": {
      handler(newValue) {
        //  实际价格=总金额-优惠金额-减少项+增加项+调整费用+额外收费项
        if (!this.viewShow) {
          this.form.qmoney =
            this.form.qtotal -
            this.form.qcheap -
            this.form.qreduce +
            this.form.qincrease +
            this.form.qchange +
            this.ExtraChargePrice;
          // console.log("优惠价", newValue, this.form.qmoney);
        }
      },
      deep: true,
    },
    // //监听减少项，计算实际价格
    "form.qreduce": {
      handler(newValue) {
        if (!this.viewShow) {
          //  实际价格=总金额-优惠金额-减少项+增加项+调整费用+额外收费项
          this.form.qmoney =
            this.form.qtotal -
            this.form.qcheap -
            this.form.qreduce +
            this.form.qincrease +
            this.form.qchange +
            this.ExtraChargePrice;
          console.log("减少项", newValue, this.form.qmoney);
        }
      },
      deep: true,
    },
    // //监听增加项，计算实际价格
    "form.qincrease": {
      handler(newValue) {
        console.log("增加项", newValue);
        if (!this.viewShow) {
          //  实际价格=总金额-优惠金额-减少项+增加项+调整费用+额外收费项
          this.form.qmoney =
            this.form.qtotal -
            this.form.qcheap -
            this.form.qreduce +
            this.form.qincrease +
            this.form.qchange +
            this.ExtraChargePrice;
          console.log("增加项", newValue, this.form.qmoney);
        }
      },
      deep: true,
      immediate: true,
    },
    //监听调整费用，计算实际价格
    "form.qchange": {
      handler(newValue) {
        if (!this.viewShow) {
          //  实际价格=总金额-优惠金额-减少项+增加项+调整费用+额外收费项
          this.form.qmoney =
            this.form.qtotal -
            this.form.qcheap -
            this.form.qreduce +
            this.form.qincrease +
            this.form.qchange +
            this.ExtraChargePrice;
          // console.log("调整费用", newValue, this.form.qmoney);
        }
      },
      deep: true,
    },

    // //监听主材总金额，计算实际价格
    // "form.qmmoney": {
    //   handler(newValue) {
    //     // 实际价格=套餐价-减少项+增加项+调整费用+主材金额
    //     this.form.qmoney =
    //       this.form.qpackage -
    //       this.form.qreduce +
    //       this.form.qincrease * 1 +
    //       this.form.qchange * 1 +
    //       this.form.qmmoney * 1;
    //     console.log("主材总金额", newValue, this.form.qmoney);
    //   },
    // },
  },
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            this.CompanyList[el.bc_id] = el.bc_name;
          });
          this.GetCompanyList = res.data;
        })
        .then(() => {
          this.queryParam.qdBcIds = this.CompanyListid.toString();
        })
        .then(() => {
          // if (this.$route.query.type == "edit") {
          //   this.loadData();
          // }
        });
    },
    // 根据分公司id查询分公司名称
    CompanyListResult(a) {
      return this.CompanyList[a];
    },
    loadData() {
      setTimeout(() => {
        this.tabledata = JSON.parse(this.$route.query.record).project;
        // console.log(" this.tabledata11111", this.tabledata);
        this.tabledata.forEach((el) => {
          el.children.forEach((item) => {
            item.childrenWpId = el.wpId;
            item.mainMaterials = JSON.stringify(item.mainMaterials);
          });
        });
        let extra = JSON.parse(this.$route.query.record).extra;
        // console.log("extra", extra);
        let NewExtraChargeValue = [];
        let NewExtraCharge = [];
        extra.forEach((item) => {
          NewExtraChargeValue.push(item.qdPId + "");
          NewExtraCharge.push({
            qdBcId: this.form.qbcId,
            qdType: "2",
            qdPId: item.qdPId,
            qdQId: this.form.qid,
          });
        });
        this.NewExtraChargeValue = NewExtraChargeValue;
        this.NewExtraCharge = NewExtraCharge;
      }, 1000);
      // console.log(
      //   "  this.NewExtraChargeValue",
      //   this.NewExtraChargeValue,
      //   this.NewExtraCharge
      // );
    },
    // 获取施工工序
    GetWorkProceList(wpBcIds) {
      let queryParam = {
        wpBcIds: wpBcIds ? wpBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["wpBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetWorkProceList(params)
        .then((res) => {
          // console.log("施工工序", res.data);
          res.data.forEach((el) => {
            this.WorkProceList[el.wpId] = el.wpName;
          });
          // 如果是新增 则展示所有施工工序
          if (this.$route.query.type == "add") {
            this.tabledata = [];
            setTimeout(() => {
              res.data.forEach((el) => {
                this.tabledata.push({
                  wpId: el.wpId,
                  children: [],
                });
              });
            });
          }
          // console.log("施工工序", res.data, this.WorkProceList.length);
          // console.log(" 施工工序 --this.tabledata", this.tabledata);
        })
        .then(() => {});
    },
    // 根据施工工序id查询施工工序名称
    WorkProceResult(a) {
      return this.WorkProceList[a];
    },
    // 获取施工项
    GetProjectList(pBcIds) {
      let queryParam = {
        pBcIds: pBcIds ? pBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["pBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetProjectList(params).then((res) => {
        res.data.forEach((el) => {
          this.ProjectList[el.pid] = el.pname;
          el.children.forEach((item) => {
            this.ProjectList[item.pid] = item.pname;
          });
        });
        // console.log("施工项", res.data);
      });
    },
    // 根据施工项id查询施工工序名称
    ProjectListResult(a) {
      return this.ProjectList[a];
    },
    // 获取套餐类别
    GetPackageTypeList(ptBcIds) {
      let queryParam = {
        ptBcIds: ptBcIds ? ptBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["ptBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetPackageTypeList(params).then((res) => {
        this.PackageTypeList = res.data;
        res.data.forEach((el) => {
          this.PackageTypeListId[el.ptId] = el.ptName;
          el.children.forEach((item) => {
            this.PackageTypeListId[item.ptId] = item.ptName;
          });
        });
      });
    },
    // 根据套餐类别id查询套餐名称
    PackageTypeListResult(a) {
      return this.PackageTypeListId[a];
    },
    // 获取套餐模版
    GetPackageTemplateList(ptBcIds) {
      let queryParam = {
        ptBcIds: ptBcIds ? ptBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["ptBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetPackageTemplateList(params).then((res) => {
        // console.log("套餐模版", res.data);
        this.PackageTemplateList = res.data;
        // 套餐模版id和价格
        res.data.forEach((el) => {
          this.PackageTypeListPrice[el.ptId] = el.ptPrice;
          if (el.children) {
            el.children.forEach((item) => {
              this.PackageTypeListPrice[item.ptId] = item.ptPrice;
            });
          }
        });

        this.PackageTemplateList.forEach((el) => {
          if (el.ptId == this.form.qptId) {
            this.form.qPtPtId = this.PackageTypeListResult(el.ptPtId); //套餐类别
            this.form.qNumber = el.ptNumber; //套餐编号
            this.form.qName = el.ptName;
            this.form.qPackageMoney = el.ptPrice;
            this.form.qpackage = el.ptPrice * this.form.qarea;
          } else {
          }
        });
      });
    },
    // 根据套餐模版id获取套餐价格
    PackageTypeListPriceResult(a) {
      return this.PackageTypeListPrice[a];
    },
    // 获取物料信息---主材
    GetProjectMaterialList(pmBcIds, pmPId) {
      let queryParam = {
        // pmPId: pmPId ? pmPId : JSON.parse(this.record).qdPId,
        pmBcIds: pmBcIds ? pmBcIds : this.CompanyListid.toString(),
        pmType: "1",
      };
      let orderParam = ["pmBcIds", "pmType"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetProjectMaterialList(params).then((res) => {
        // console.log("物料信息", res.data);
        res.data.forEach((el) => {
          this.ProjectMaterialListMainLis[el.pmMId] = el.tmaterial.mName;
        });
        this.ProjectMaterialListMain = res.data;
        // console.log("this.ProjectList", this.ProjectList);
      });
    },
    // 根据物料id查询name
    ProjectMaterialResult(a) {
      return this.ProjectMaterialListMainLis[a];
    },
    // 查询套餐详情
    GetPackageDetail(pdPtId, pdBcId) {
      let queryParam = {
        pdPtId: pdPtId,
        pdBcId: pdBcId,
      };
      let orderParam = ["pdPtId", "pdBcId"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetPackageDetail(params).then((res) => {
        // console.log("套餐详情", res.data);
        this.oldTableData = res.data.project;

        // console.log(" this.oldTableData ", this.oldTableData);
        // 新增时选择套餐，默认添加套餐所包含的施工项，如果是编辑，则不添加
        if (this.$route.query.type == "add") {
          // 表格数据
          let project = res.data.project;
          let price = 0;
          let newtabledata = [];
          project.forEach((el, index) => {
            newtabledata.push({
              wpId: el.wpId,
            });
          });

          setTimeout(() => {
            project.forEach((el) => {
              newtabledata.forEach((item) => {
                if (item.wpId == el.wpId) {
                  let children = [];
                  if (el.children) {
                    el.children.forEach((child) => {
                      let mainMaterials = [];
                      child.tproject.mainMaterials.forEach((mainel, index) => {
                        mainMaterials.push({
                          id: index,
                          maMId: mainel.pmMId,
                          maQuantity: 0,
                          maProvider: mainel.pmProvider ? mainel.pmProvider : 1,
                          maType: "1", //主材
                          maAttribute: "1",
                          // maPAttribute   父级是否为增减项
                          maBcId: mainel.pmBcId,
                          maLoss: mainel.pmLoss,
                          maUnitUsage: mainel.pmUnitUsage,
                          maUnit: mainel.tmaterial.mUnit,
                          maSalesPrice: mainel.tmaterial.mSalesPrice,
                          maContain: mainel.pmContain,
                        });
                      });
                      let subMaterials = [];
                      child.tproject.subMaterials.forEach((mainel, index) => {
                        subMaterials.push({
                          id: index,
                          maMId: mainel.pmMId,
                          maQuantity: 0,
                          maProvider: mainel.pmProvider ? mainel.pmProvider : 1,
                          maType: "2", //辅材
                          maAttribute: "1",
                          // maPAttribute   父级是否为增减项
                          maBcId: mainel.pmBcId,
                          maLoss: mainel.pmLoss,
                          maUnitUsage: mainel.pmUnitUsage,
                          maUnit: mainel.tmaterial.mUnit,
                          maSalesPrice: mainel.tmaterial.mSalesPrice,
                          maContain: mainel.pmContain,
                        });
                      });
                      setTimeout(() => {
                        children.push({
                          qdPId: child.pdPId,
                          qdRatio: child.pdRatio,
                          qdPrice: child.pdPrice,
                          qdWpId: el.wpId,
                          // qdQuantity: this.form.qarea * child.pdRatio,
                          childrenWpId: el.wpId,
                          // 优惠单价
                          qdCheapPrice: child.pdCheapPrice,
                          // 优惠金额
                          qdCheapMoney: 0,
                          //  施工项金额
                          qdMoney: 0,
                          // qdMoney: child.pdQuantity * child.pdPrice,
                          // child.pdPrice * this.form.qarea * child.pdRatio,
                          //  主材金额
                          qdMPrice: 0,
                          qdAttribute: 1, //属性  套餐
                          qdUnit: child.tproject.punit, //单位
                          qdFollowChange: child.pdFollowChange, //施工量是否随工地面积改变
                          qdQuantity: child.pdQuantity, //施工量
                          PtId: child.tproject.tprojectType.ptId, //施工项类型
                          mainMaterials: JSON.stringify(mainMaterials), //主材
                          subMaterials: subMaterials, // 辅材
                        });
                      }, 10);
                    });
                  }
                  // console.log(children);
                  item.children = children;
                }
              });
            });
            this.tabledata = newtabledata;
          });
          // console.log("套餐施工项", this.tabledata);
          // console.log("套餐详情-额外收费项", this.ExtraChargeList);
          let extra = res.data.extra;
          let id = [];
          let NewExtraCharge = [];
          extra.forEach((el, index) => {
            id.push(el.pdPId);
            NewExtraCharge.push({
              qdBcId: this.form.qbcId,
              qdType: "2",
              qdPId: el.pdPId,
              // qdQId: "",
            });
          });
          // this.NewExtraCharge = id;
          this.NewExtraChargeValue = id;
          this.NewExtraCharge = NewExtraCharge;
        }

        // this.oldTableData = this.tabledata; //记录原始表格数据，计算增加项和减少项
      });
    },
    // 点击编辑，隐藏详情  显示编辑页面
    showEdit() {
      this.$route.meta.title = "报价编辑";
      this.viewShow = false;
    },
    afterVisibleChange(val) {},
    // 展开抽屉
    showDrawer(name, title, type, record) {
      // console.log("showDrawer_record", record, this.form);
      this.visibleDrawer = true;
      this.DrawerTitle = title;
      this.QuotationEditShow = false;
      this.QuotationViewShow = false;
      this[name] = true;
      if (record) {
        this.record = record;
      }
      this.recordType = type;
      this.record.qdBcId = this.form.qbcId;
      this.record.qdQId = this.form.qid;
      this.record.qarea = this.form.qarea; //施工量（面积）
      // this.record.qdPtId = this.form.qptId; //套餐id
    },
    // 查询额外收费项列表
    GetExtraChargeList(ecBcIds) {
      let queryParam = {
        ecBcIds: ecBcIds ? ecBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["ecBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetExtraChargeList(params).then((res) => {
        let ExtraChargeList = res.data;
        ExtraChargeList.forEach((el) => {
          el.ecId = el.ecId + "";
        });
        this.ExtraChargeList = ExtraChargeList;
        // console.log("查询额外收费项列表", ExtraChargeList);
        res.data.forEach((el) => {
          this.ExtraChargeListId[el.ecId] = el.ecName;
        });
      });
    },
    // 根据额外收费项的id获取name
    ExtraChargeListResult(e) {
      return this.ExtraChargeListId[e];
    },
    // 额外收费项的选择
    changeradioBtn(e) {
      // pdBcId 分公司id  pdPId 额外收费项id
      //pdType 类型2  pdPrice价格   pdPtId模版id
      // console.log("额外收费项的选择", e);
      this.NewExtraCharge = [];
      e.forEach((el, index) => {
        this.NewExtraCharge.push({
          qdBcId: this.form.qbcId,
          qdType: "2",
          qdPId: el,
          qdQId: this.form.qid,
          qdOrder: index,
        });
      });
      // console.log("this.NewExtraCharge", this.NewExtraCharge);
    },
    //
    handleChangeExtra(e) {
      console.log("e", e);
      this.NewExtraCharge = [];
      e.forEach((el, index) => {
        this.NewExtraCharge.push({
          qdBcId: this.form.qbcId,
          qdType: "2",
          qdPId: el,
          qdQId: this.form.qid,
          qdOrder: index,
        });
      });
    },
    onClose() {
      this.visibleDrawer = false;
      this.QuotationEditShow = false;
      this.record = {};
    },
    // 表格编辑
    tabledataChange(event) {
      // console.log(
      //   "this.$refs.QuotationEdit.emitForm",
      //   this.$refs.QuotationEdit.emitForm
      // );
      let emitForm = JSON.parse(this.$refs.QuotationEdit.emitForm);
      // console.log("tabledataChange", emitForm);
      // 下标大于-1   证明存在   施工工序
      let findIndex = -1;
      // 判断是否重复设置施工项
      let pdpIdindex = -1;
      // let tabledata = this.tabledata;
      let tabledata1 = JSON.stringify(this.tabledata);
      let tabledata = JSON.parse(tabledata1);
      if (this.tabledata.length == 0) {
        this.onClose();
        this.tabledata.push(emitForm);
      } else {
        findIndex = this.tabledata.findIndex((item) => {
          return item.wpId == emitForm.wpId;
        });
        if (findIndex > -1) {
          this.tabledata.forEach((el, index) => {
            if (el.wpId == emitForm.wpId) {
              // el.children.push(emitForm.children[0]);
              // this.onClose();
              pdpIdindex = el.children.findIndex((item) => {
                return item.qdPId == emitForm.children[0].qdPId;
              });
            } else {
              // this.$message.error("施工项重复设置");
            }
          });
        } else {
          this.tabledata.push(emitForm);
          this.onClose();
        }
      }
      // 判断新增数据是否为套餐内包含的数据
      let oldoldTableDataIndex = -1;
      // console.log("  this.oldTableData", this.oldTableData);

      this.oldTableData.forEach((el) => {
        // 判断施工工序
        if (el.wpId == emitForm.wpId) {
          // 判断施工项
          if (el.children) {
            el.children.forEach((elChild, index) => {
              if (elChild.pdPId == emitForm.children[0].qdPId) {
                oldoldTableDataIndex = index;
              }
            });
          }
        }
      });
      // 如果是新增
      if (this.recordType == "add") {
        console.log("add");

        // 1---属性为套餐或者调整项
        if (
          emitForm.children[0].qdAttribute == "1" ||
          emitForm.children[0].qdAttribute == "2"
        ) {
          if (oldoldTableDataIndex > -1) {
            // 判断是否重复设置施工项 ，属性为套餐或者部分调整的施工项只能有一个

            if (pdpIdindex == -1) {
              tabledata.forEach((el) => {
                if (el.wpId == emitForm.wpId) {
                  if (el.children) {
                    el.children.push(emitForm.children[0]);
                    this.onClose();
                  }
                }
              });
            } else {
              this.$message.error("不可重复添加套餐项");
            }
          } else {
            this.$message.error("选用的套餐中不包含此施工项，可以设置为增加项");
          }
        }
        // 2---属性为增加项
        if (emitForm.children[0].qdAttribute == "3") {
          if (pdpIdindex == -1) {
            tabledata.forEach((el) => {
              if (el.wpId == emitForm.wpId) {
                if (el.children) {
                  el.children.push(emitForm.children[0]);
                  this.onClose();
                }
              }
            });
          } else {
            // console.log("添加增加项--施工项0");
            let index = -1;
            tabledata.forEach((el) => {
              if (el.wpId == emitForm.wpId) {
                if (el.children) {
                  el.children.forEach((elChild, elChildIndex) => {
                    if (
                      elChild.qdPId == emitForm.children[0].qdPId &&
                      elChild.qdAttribute == "3"
                    ) {
                      index = elChildIndex;
                    }
                  });
                }
              }
            });
            // 如果不存在相同施工项的增加项
            if (index == -1) {
              tabledata.forEach((el) => {
                if (el.wpId == emitForm.wpId) {
                  console.log("增加项1111", index);
                  el.children.push(emitForm.children[0]);
                  this.onClose();
                }
              });
            } else {
              console.log("增加项", index);
              this.$message.error("不可添加重复的增加项");
            }
          }
        }
        // 3----属性为减少项
        if (emitForm.children[0].qdAttribute == "4") {
          // 不是套餐内
          if (oldoldTableDataIndex == -1) {
            this.$message.error("选用套餐不包含此施工项，不可设置为减少项");
          } else {
            //  表格内有此施工项的套餐或部分调整，才能有减少项
            let index1or2 = -1;
            let index4 = -1;
            tabledata.forEach((el) => {
              if (el.wpId == emitForm.wpId) {
                if (el.children) {
                  // 表格内包含此施工项
                  if (pdpIdindex > -1) {
                    // 判断表格内是否有此施工项的部分调整或否
                    index1or2 = el.children.findIndex((item) => {
                      return (
                        item.qdAttribute == "1" ||
                        item.qdAttribute == "2" ||
                        item.qdAttribute == null
                      );
                    });
                    // 判断表格内是否含有此施工项的减少项
                    index4 = el.children.findIndex((item) => {
                      return item.qdAttribute == "4";
                    });
                  } else {
                    // 表格内不包含此施工项
                    this.$message.error(
                      "已选施工项中不包含此施工项，不可设置为减少项"
                    );
                  }
                }
              }
            });
            console.log("减少项", index1or2, index4);
            if (index1or2 > -1) {
              if (index4 == -1) {
                tabledata.forEach((el) => {
                  if (el.wpId == emitForm.wpId) {
                    el.children.push(emitForm.children[0]);
                    this.onClose();
                  }
                });
              } else {
                this.$message.error("不可添加重复的减少项");
              }
            }
            // else {
            //   this.$message.error("已选施工项中不包含此施工项，不可设置为减少项");
            // }
            // }
          }
        }
      }
      // 如果是修改

      if (this.recordType == "edit") {
        console.log("edit");
        // 如果存在这条施工项
        // if (pdpIdindex > -1) {
        // 再判断属性是否相同，如果相同直接赋值
        tabledata.forEach((el, index) => {
          if (el.wpId == emitForm.wpId) {
            el.children.forEach((item, index) => {
              if (
                item.qdPId == emitForm.children[0].qdPId
                // &&      item.qdAttribute == emitForm.children[0].qdAttribute
              ) {
                // item = emitForm.children[0];
                // el.children.push(emitForm.children[0]);
                el.children[index] = emitForm.children[0];
                // console.log("111111111", item, emitForm.children[0]);
                console.log("item", item);
                this.onClose();
              }
            });
          }
        });
        // }
      }

      // console.log("tabledata", JSON.parse(JSON.stringify(tabledata)));

      this.tabledata = tabledata;
      // console.log(" this.tabledata ", this.tabledata);

      // 计算总金额
      this.countQtotal(this.tabledata);
      // 计算增加项
      this.countAddItem(JSON.stringify(this.tabledata));
      // 计算减少项
      this.countCutItem(this.tabledata);
    },
    // 计算总金额 、 调整费用
    countQtotal(tabledata) {
      // 总金额=属性为套餐或者部分调整的总金额之和
      let qtotal = 0;
      let maAdjPrice = 0; //调整项费用
      tabledata.forEach((el) => {
        if (el.children) {
          el.children.forEach((elChild) => {
            if (
              elChild.qdAttribute == "1" ||
              elChild.qdAttribute == "2"
              // || elChild.qdAttribute == null
              // elChild.qdAttribute == "3"
            ) {
              qtotal = qtotal * 1 + elChild.qdMoney;
            }
            // 计算调整项费用
            if (elChild.tmaterialAdjustments) {
              elChild.tmaterialAdjustments.forEach((Adjus) => {
                maAdjPrice = maAdjPrice * 1 + Adjus.maAdjPrice * 1;
              });
              // console.log("调整费用", maAdjPrice, elChild.tmaterialAdjustments);
            }
          });
        }
      });
      this.form.qtotal = qtotal;
      console.log("计算总金额", qtotal);
      this.form.qchange = maAdjPrice; //非标项调整费用
    },
    // 计算增加项
    countAddItem(value) {
      let tabledata = JSON.parse(value);
      // console.log("tabledata", tabledata);

      let qincreaseAdd = 0; //增加项
      let qincreaseAdj = 0; //部分调整
      tabledata.forEach((el) => {
        if (el.children.length > 0) {
          el.children.forEach((elChild) => {
            console.log("elChild", elChild);

            let addMain = 0; //主材增加项金额
            let addSub = 0; //辅材增加项金额
            // 主材

            let mainMaterials =
              typeof elChild.mainMaterials == "string"
                ? JSON.parse(elChild.mainMaterials)
                : elChild.mainMaterials;

            // console.log("mainMaterials", mainMaterials);
            if (mainMaterials) {
              mainMaterials.forEach((elMain) => {
                // elMain.maAttribute  是否为增加项
                if (
                  elMain.maAttribute == "3" &&
                  elMain.maMId != null &&
                  elMain.maMId != ""
                ) {
                  // console.log("elChild", elChild);
                  let maSalesPrice = elMain.maSalesPrice
                    ? elMain.maSalesPrice
                    : elMain.tmaterial.mSalesPrice;
                  // 属性为增加项时
                  // 金额=用量*单价
                  let price = elMain.maQuantity * maSalesPrice;
                  addMain = addMain * 1 + price * 1;
                  console.log(
                    "add---",
                    elMain,
                    elMain.maQuantity,
                    maSalesPrice,
                    price
                  );
                }
              });
            }

            // 辅材
            if (elChild.subMaterials) {
              elChild.subMaterials.forEach((elSub) => {
                if (
                  elSub.maAttribute == "3" &&
                  elSub.maMId != null &&
                  elSub.maMId != ""
                ) {
                  // 属性为增加项时
                  // 金额=用量*单价
                  let maSalesPrice = elSub.maSalesPrice
                    ? elSub.maSalesPrice
                    : elSub.tmaterial.mSalesPrice;
                  let price = elSub.maQuantity * maSalesPrice;
                  addSub = addSub * 1 + price * 1;
                  console.log(
                    "add---addSub",
                    elSub,
                    elSub.maQuantity,
                    maSalesPrice,
                    price
                  );
                }
              });
            }

            // 如果属性为增加项
            if (elChild.qdAttribute == "3") {
              //  增加项金额=主材增加项+辅材增加项+总金额
              qincreaseAdd =
                qincreaseAdd * 1 +
                addMain * 1 +
                addSub * 1 +
                elChild.qdMoney * 1;
            } else if (elChild.qdAttribute == "2") {
              //  部分调整项金额=主材增加项+辅材增加项
              qincreaseAdj = qincreaseAdj * 1 + addMain * 1 + addSub * 1;
            }
            console.log("qincreaseAdj", qincreaseAdj, addMain, addSub);
          });
        }
      });
      // 增加项=增加项（主材增加项+辅材增加项+金额）+部分调整项（主材增加项+辅材增加项）
      this.form.qincrease = qincreaseAdd * 1 + qincreaseAdj * 1;
      console.log(
        "this.form.qincrease",
        this.form.qincrease,
        "qincreaseAdd",
        qincreaseAdd,
        "qincreaseAdj",
        qincreaseAdj
      );
    },
    // 计算减少项
    countCutItem(tabledata) {
      let qreduceCut = 0; //减少项的金额
      let qreduceAdd = 0; //增加项的减少项
      let qreduceAdj = 0; //部分调整的减少项
      tabledata.forEach((el) => {
        if (el.children) {
          el.children.forEach((elChild) => {
            let cutMain = 0; //主材减少项金额
            let cutSub = 0; //辅材减少项金额
            // 主材减少项
            // 主材
            let mainMaterials =
              typeof elChild.mainMaterials == "string"
                ? JSON.parse(elChild.mainMaterials)
                : elChild.mainMaterials;
            if (mainMaterials) {
              mainMaterials.forEach((elMain) => {
                // elMain.maAttribute  是否为减少项
                if (elMain.maAttribute == "4" && elMain.maMId != null) {
                  // 属性为减少项时
                  // 金额=用量*单价
                  let maSalesPrice = elMain.maSalesPrice
                    ? elMain.maSalesPrice
                    : elMain.tmaterial.mSalesPrice;
                  let price = elMain.maQuantity * maSalesPrice;
                  cutMain = cutMain * 1 + price * 1;
                }
              });
            }

            // 辅材减少项
            if (elChild.subMaterials) {
              elChild.subMaterials.forEach((elSub) => {
                if (elSub.maAttribute == "4" && elSub.maMId != null) {
                  // 属性为减少时
                  // 金额=用量*单价
                  let maSalesPrice = elSub.maSalesPrice
                    ? elSub.maSalesPrice
                    : elSub.tmaterial.mSalesPrice;
                  let price = elSub.maQuantity * maSalesPrice;
                  cutSub = cutSub * 1 + price * 1;
                }
              });
            }

            if (elChild.qdAttribute == "4") {
              // 如果属性为减少项  金额之和
              qreduceCut = qreduceCut * 1 + elChild.qdMoney;
            } else if (elChild.qdAttribute == "3") {
              // 增加项=主减+辅减
              qreduceAdd = qreduceAdd * 1 + cutMain * 1 + cutSub * 1;
            } else if (elChild.qdAttribute == "2") {
              // 部分调整项=主减+辅减
              qreduceAdj = qreduceAdj * 1 + cutMain * 1 + cutSub * 1;
            }
          });
        }
      });
      // 减少项=增加项（主材减少项+辅材减少项）+部分调整型（主材减少项+辅材减少项）+减少项（金额）
      this.form.qreduce = qreduceAdd * 1 + qreduceAdj * 1 + qreduceCut * 1;
      console.log(
        "减少项this.form.qreduce",
        this.form.qreduce,
        "qreduceAdd",
        qreduceAdd,
        "qreduceAdj",
        qreduceAdj,
        "qreduceCut",
        qreduceCut
      );
    },
    // 提交按钮
    submitClick() {
      // console.log("form", this.form);
      if (this.$route.query.type == "edit") {
        this.UpdateQuotation();
        // this.UpdateQuotationDetailList();
      } else {
        // 新建基本信息
        this.AddQuotation();
      }
    },
    // 新建基本信息
    AddQuotation() {
      let params = this.form;
      console.log("this.form", this.form);
      params.project = this.tabledata;

      params.project.forEach((el) => {
        el.children.forEach((item, index) => {
          let mainMaterials = [];
          if (typeof item.mainMaterials == "string") {
            mainMaterials = JSON.parse(item.mainMaterials);
          } else {
            mainMaterials = item.mainMaterials;
          }
          item.qdWpId = el.wpId;
          item.qdQid = this.form.qid;
          item.qdBcId = this.form.qbcId;
          item.qdType = "1"; //1施工项  2额外施工项
          item.qdOrder = index;
          item.mainMaterials = mainMaterials;
        });
      });
      // 额外收费项
      params.extra = this.NewExtraCharge;
      // 额外收费项金额
      params.qextra = this.ExtraChargePrice;
      AddQuotation(params).then((res) => {
        // console.log(res.data);
        // this.AddQuotationDetailList(res.data);
        if (res.code == 200) {
          this.$message.success("新建报价成功");
          this.$router.push({
            path: "/QuotationList",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 修改基本信息
    UpdateQuotation() {
      let params = this.form;
      params.project = this.tabledata;
      params.project.forEach((el) => {
        el.children.forEach((item, index) => {
          item.qdWpId = el.wpId;
          item.qdQid = this.form.qid;
          item.qdBcId = this.form.qbcId;
          item.qdType = "1"; //1施工项  2额外施工项
          item.qdOrder = index;
          // item.qreduce = 3305.1;
          item.mainMaterials = JSON.parse(item.mainMaterials);
        });
      });
      // 额外收费项
      params.extra = this.NewExtraCharge;
      // 额外收费项金额
      params.qextra = this.ExtraChargePrice;
      // console.log(params, this.tabledata);
      UpdateQuotation(params).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.$message.success("修改报价成功");
          this.$router.push({
            path: "/QuotationList",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    onDelete(record) {
      console.log("delete", record, this.oldTableData);
      const tabledata = [...this.tabledata];
      let JSONRecord = JSON.stringify(record);

      // this.tabledata = tabledata.filter(item => item.key !== record.pdPId);
      tabledata.forEach((item) => {
        // console.log("item", item);
        let newchildren = [];
        if (item.wpId == record.childrenWpId) {
          item.children.forEach((el) => {
            let JSONel = JSON.stringify(el);
            // if (el.qdPId != record.qdPId) {
            //   newchildren.push(el);
            //   // console.log(el);
            // }
            if (JSONel != JSONRecord) {
              newchildren.push(el);
            }
          });
          item.children = newchildren;
        }
      });
      this.tabledata = tabledata;
      // 计算总金额
      this.countQtotal(this.tabledata);
      // 计算增加项
      this.countAddItem(JSON.stringify(this.tabledata));
      // 计算减少项
      this.countCutItem(this.tabledata);
    },
    // 跳转到报价列表页面
    cancellClick() {
      this.$router.push({
        path: "/QuotationList",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.infoTit {
  font-family: Microsoft YaHei UI, Microsoft YaHei UI;
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.basicInfo {
  text-align: left;

  .displayFlex {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .margin_top {
    margin-top: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.margin_right {
  margin-right: 10px;
}
.btnsDisplayflex {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .btnsDisplayflex {
    div {
      margin-right: 20px;
    }
  }
  div {
    // margin-right: 20px;
    font-weight: 800;
  }
  /deep/ .ant-btn {
    margin-right: 20px;
  }
  .btnsDisplayflex_inner {
    margin-left: 10px;
    display: flex;
    div {
      margin-left: 10px;
    }
  }
  /deep/.ant-col-3 {
    width: 150px;
  }
  .btnsDisplayflexPrice {
    font-weight: 500;
    // margin-left: 50px;
  }
}
.editable-row-operations a {
  margin-right: 8px;
}
.editBtn {
  margin-right: 20px;
}
.delName {
  color: red;
}
.addBtn {
  text-align: left;
  margin-top: 20px;
}
.bottom {
  //   margin: 10px 50px;
  .displayFlex {
    display: flex;
    justify-content: space-between;
  }
}
/deep/ .ant-table-tbody > tr {
  height: 90px;
}
/deep/.ant-checkbox-group {
  width: 80%;
  text-align: left;
}
.basicInfo_view {
  display: flex;
  flex-wrap: wrap;

  div {
    width: 300px;
    margin-right: 20px;
    margin-top: 20px;
  }
  .basicInfo_view_hidden {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
// /deep/.ant-input-disabled
/deep/.ant-input[disabled],
.ant-input-disabled,
.ant-select-disabled {
  color: rgba(0, 0, 0, 1);
}
.padding_bottom {
  padding-bottom: 100px;
}

.basicInfo_edit {
  /deep/.ant-row {
    width: 400px;
    margin: 10px 50px 10px 0;
  }
  .colorChange {
    color: #ff834c;
    font-weight: 800;
  }
  .width240 {
    width: 240px;
  }
  /deep/ .width80 {
    width: 80px;
    margin-right: 10px;
  }
  /deep/ .width140 {
    width: 140px;
    margin-right: 10px;
  }
  .width50 {
    width: 50px;
  }
  .textareaWidth {
    // /deep/ .ant-form-item > .ant-form-item-label {
    //   // width: 80px;
    // }
    /deep/.ant-form-item {
      width: 98%;
    }
    /deep/.ant-form-item-control-wrapper {
      width: 90%;
    }
  }
}
/deep/ .a-tooltip {
  width: 800px !important;
}
/deep/ .ant-tooltip-inner {
  width: 800px !important;
  background-color: red;
}
/deep/.ant-tooltip-content {
  width: 800px !important;
  background-color: red;
}
</style>
